import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Heading,
  Input,
  Text,
  Box,
} from '@chakra-ui/react';

import data from '../../data.json';

const Step1 = ({
  cpf, currentStep, handleCaptcha, handleChange,
}) => {
  if (currentStep !== 1) {
    return null;
  }
  return (
    <>
      <Heading as="h1" textAlign="center">Cadastro de tutor</Heading>
      <Text mb={5}>
        Olá, para iniciarmos seu cadastro precisaremos de algumas informações.
        <br />
        Para iniciar, digite abaixo somente os números de seu CPF.
      </Text>
      <Input
        id="cpf"
        name="cpf"
        type="number"
        placeholder="CPF"
        value={cpf}
        onChange={handleChange}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        m={2}
      >
        <ReCAPTCHA
          className="g-recaptcha"
          sitekey={data.cadastro.captchaKey}
          onChange={handleCaptcha}
        />
      </Box>
    </>
  );
};

Step1.propTypes = {
  cpf: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  handleCaptcha: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Step1;
