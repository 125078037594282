import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Text } from '@chakra-ui/react';

import data from '../../data.json';

const Step5 = ({ currentStep }) => {
  if (currentStep !== 5) {
    return null;
  }
  return (
    <>
      <Heading as="h1">Cadastro finalizado</Heading>
      <Text>{data.cadastro.textoFinal}</Text>
    </>
  );
};

Step5.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default Step5;
