import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Progress,
  SlideFade,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Step1 from '../components/cadastro/Step1';
import Step2 from '../components/cadastro/Step2';
import Step3 from '../components/cadastro/Step3';
import Step4 from '../components/cadastro/Step4';
import Step5 from '../components/cadastro/Step5';

import data from '../data.json';
import api from '../providers/api';

class Cadastro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cadastrado: false,
      inscAtivas: false,
      currentStep: 1,
      captchaToken: '',
      cpf: '',
      name: '',
      phone: '',
      email: '',
      password: '',
      repeatPassword: '',
      escola: '',
      cargo: '',
      natureza: '',
      error: null,
    };
    this.validaCampos = this.validaCampos.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  componentDidMount() {
    // formato: mm/dd/yyyy
    const prazo = data.inscricoes.split(' - ').map((dateStr) => new Date(dateStr));
    const currDate = new Date();
    this.setState({ inscAtivas: (currDate >= prazo[0] && currDate <= prazo[1]) });
  }

  componentDidUpdate() {
    const { error } = this.state;
    if (error) {
      setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleCaptcha(captchaToken) {
    this.setState({ captchaToken });
  }

  validaCampos(passo) {
    return new Promise((resolve, reject) => {
      const campos = { ...this.state };

      if (passo === 1) {
        if (campos.cpf === '' || campos.cpf.toString().length < 11 || campos.cpf.toString().length > 11) reject(new Error('CPF inválido'));
        if (campos.captchaToken === '') reject(new Error('Captcha inválida'));
        api.get(`/tutor?cpf=${campos.cpf}`)
          .then((result) => {
            if (result.data.registered) reject(new Error('CPF já cadastrado')); else resolve();
          })
          .catch((err) => reject(new Error(`Ocorreu um erro de conexão: ${err.message}`)));
      } else if (passo === 2) {
        if (campos.name === '' || campos.phone === '') reject(new Error('Preencha todos os campos'));
        resolve();
      } else if (passo === 3) {
        if (campos.email === '' || campos.password === '' || campos.repeatPassword === '') reject(new Error('Preencha todos os campos'));
        if (campos.repeatPassword !== campos.password) reject(new Error('As senhas não coincidem'));
        resolve();
      } else if (passo === 4) {
        if (campos.escola === '' || campos.cargo === '' || campos.natureza === '') reject(new Error('Preencha todos os campos'));
        api.post('/users', {
          nome: campos.name,
          cpf: campos.cpf,
          email: campos.email,
          senha: campos.password,
          telefone: campos.phone,
          escola: campos.escola,
          cargo: campos.cargo,
          natureza: campos.natureza,
        })
          .then(() => resolve())
          .catch((err) => reject(new Error(`Erro ao cadastrar no sistema: ${err.message}`)));
      }
    });
  }

  prev() {
    let { currentStep } = this.state;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({ currentStep });
  }

  next() {
    let { currentStep } = this.state;
    this.validaCampos(currentStep)
      .then(() => {
        currentStep = currentStep >= 5 ? 6 : currentStep + 1;
        this.setState({ currentStep });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  previousStep() {
    const { currentStep } = this.state;
    if (currentStep >= 3 && currentStep < 5) {
      return (
        <Button
          flex="1"
          onClick={this.prev}
          type="button"
          variant="outline"
        >
          Passo anterior
        </Button>
      );
    }
    return null;
  }

  nextStep() {
    const { currentStep } = this.state;
    if (currentStep < 5) {
      return (
        <Button
          flex="1"
          onClick={this.next}
          type="button"
          variant="solid"
        >
          Próximo passo
        </Button>
      );
    }
    return null;
  }

  render() {
    const {
      inscAtivas,
      currentStep,
      cpf,
      name,
      phone,
      email,
      password,
      repeatPassword,
      escola,
      cargo,
      natureza,
      error,
    } = this.state;
    const { location } = this.props;
    if (inscAtivas) {
      return (
        <Layout location={location.pathname}>
          <Container>
            <Box as="form">
              <Center mt={6}>
                <StaticImage src="../assets/icon.svg" alt="" width={128} />
              </Center>
              <SlideFade in={error !== null} unmountOnExit>
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Ocorreu um erro com seu cadastro!</AlertTitle>
                  <AlertDescription>{error != null && error.message}</AlertDescription>
                </Alert>
              </SlideFade>
              <Step1
                currentStep={currentStep}
                handleChange={this.handleChange}
                handleCaptcha={this.handleCaptcha}
                cpf={cpf}
              />
              <Step2
                currentStep={currentStep}
                handleChange={this.handleChange}
                name={name}
                phone={phone}
              />
              <Step3
                currentStep={currentStep}
                handleChange={this.handleChange}
                email={email}
                password={password}
                repeatPassword={repeatPassword}
              />
              <Step4
                currentStep={currentStep}
                handleChange={this.handleChange}
                escola={escola}
                cargo={cargo}
                natureza={natureza}
              />
              <Step5
                currentStep={currentStep}
              />
              <ButtonGroup colorScheme="teal" isAttached my={3} w="100%">
                {this.previousStep()}
                {this.nextStep()}
              </ButtonGroup>
              <Progress colorScheme="teal" my={3} value={currentStep * 20} />
            </Box>
          </Container>
        </Layout>
      );
    }
    return (
      <Layout>
        <Container>
          <Center>
            <StaticImage src="../assets/icon.svg" alt="" width={256} />
          </Center>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>O periodo de inscrições encerrou.</AlertTitle>
          </Alert>
        </Container>
      </Layout>
    );
  }
}

Cadastro.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Cadastro;
