import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Input, Stack, Text, VStack,
} from '@chakra-ui/react';

const Step3 = ({
  currentStep, email, handleChange, password, repeatPassword,
}) => {
  if (currentStep !== 3) {
    return null;
  }
  return (
    <>
      <Heading as="h1">Acesso</Heading>
      <Text mb={5}>
        Informe abaixo seu email de preferência e uma senha,
        esses dados serão utilizado para acessar o sistema.
      </Text>
      <VStack mb={5}>
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="exemplo@gmail.com"
          value={email}
          onChange={handleChange}
        />
        <Stack direction={{ base: 'column', md: 'row' }} w="100%">
          <Input
            id="senha"
            name="password"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={handleChange}
          />
          <Input
            id="repeatPassword"
            name="repeatPassword"
            type="password"
            placeholder="Repetir senha"
            value={repeatPassword}
            onChange={handleChange}
          />
        </Stack>
      </VStack>
    </>
  );
};

Step3.propTypes = {
  currentStep: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  repeatPassword: PropTypes.string.isRequired,
};

export default Step3;
