import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Heading, Input, Radio, RadioGroup, Stack, Text, VStack,
} from '@chakra-ui/react';

const Step4 = ({
  currentStep, cargo, escola, handleChange, natureza,
}) => {
  if (currentStep !== 4) {
    return null;
  }
  return (
    <>
      <Heading as="h1">Escola</Heading>
      <Text>
        Seu cadastro está quase completo.
        Precisamos agora que você insira os dados da escola a qual você representará.
        <br />
        Caso a escola possua mais de uma sede, insira o nome separado por um hífen:
      </Text>
      <Text as="i">Colégio OCI - sede Pici</Text>
      <VStack mt={5}>
        <Stack direction={{ base: 'column', md: 'row' }} w="100%">
          <Input
            id="escola"
            name="escola"
            type="text"
            placeholder="Escola"
            value={escola}
            onChange={handleChange}
          />
          <Input
            id="cargo"
            name="cargo"
            type="text"
            placeholder="Cargo"
            value={cargo}
            onChange={handleChange}
          />
        </Stack>
        <FormControl>
          <FormLabel>Natureza da Escola</FormLabel>
          <RadioGroup name="natureza" value={natureza}>
            <Stack direction={['column', 'row']}>
              <Radio value="0" onChange={handleChange}>Pública</Radio>
              <Radio value="1" onChange={handleChange}>Privada</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </VStack>
    </>
  );
};

Step4.propTypes = {
  currentStep: PropTypes.number.isRequired,
  cargo: PropTypes.string.isRequired,
  escola: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  natureza: PropTypes.string.isRequired,
};

export default Step4;
