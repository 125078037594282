import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Input, Stack, Text,
} from '@chakra-ui/react';

const Step2 = ({
  currentStep, handleChange, name, phone,
}) => {
  if (currentStep !== 2) {
    return null;
  }
  return (
    <>
      <Heading as="h1">Dados pessoais</Heading>
      <Text>
        Seu CPF está apto a ser cadastrado no sistema.
        <br />
        Informe abaixo seu nome completo e telefone para contato.
      </Text>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        my={5}
      >
        <Input
          id="nome"
          name="name"
          type="text"
          placeholder="Nome completo"
          value={name}
          onChange={handleChange}
        />
        <Input
          id="telefone"
          name="phone"
          type="tel"
          placeholder="Telefone"
          value={phone}
          onChange={handleChange}
        />
      </Stack>
    </>
  );
};

Step2.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default Step2;
